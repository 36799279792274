import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'PoemsCreate',
    data() {
        return {
            poem: {
                name: null,
                body: null,
                author: null
            },
            fileAudio: null,
            fileCoverImage: null,
            fileImage: null
        }
    },
    components: {
        adminLayout
    },
    methods: {
        createPoem() {
            const self = this
            let formData = new FormData()

            formData.append('name', self.poem.name)
            formData.append('body', self.poem.body)
            formData.append('author', self.poem.author)
            if(self.fileCoverImage) formData.append('cover_image', self.fileCoverImage)
            if(self.fileImage) formData.append('image', self.fileImage)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/poems',
                method: 'POST',
                data: formData
            }).then(function() {
                self.$router.push({ name: 'admin.poems' })

                self.notifySuccess()
            })
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        },
        handleFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileCoverImage = null
            }

            this.fileCoverImage = files[0]
        },
        handleFileImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileImage = null
            }

            this.fileImage = files[0]
        }
    }
}